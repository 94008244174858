import * as React from "react"
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import {StaticImage} from "gatsby-plugin-image"

import Layout from "../components/layout"
import * as styles from "../components/index.module.css"


const IndexPage = () => (
    <Layout>
        <div className={styles.textCenter}>

            <Grid container sx={{width: '100%'}}>
                <Grid item xs={12}>
                    <Box>
                        <StaticImage
                            src="../images/img_07.JPG"
                            loading="eager"

                            quality={95}
                            formats={["auto", "webp", "avif"]}
                            alt=""
                            style={{marginBottom: `var(--space-3)`}}
                        />
                    </Box>
                </Grid>
            </Grid>

        </div>
    </Layout>
)

export default IndexPage